<template>
  <div class="policy-detail-container">
    <div class="banner-limit flex-column flex-start policy-detail-content">
      <div class="flex-row-between detail-wrap">
        <!-- left -->
        <div class="flex-column flex-start detail-left">
          <div class="detail-title">
            <h1>{{ id }}</h1>
            <div class="share-wrap"></div>
            <div class="flex-row-between title-wrap">
              <div>
                主管部门：漯河市发展和改革局 时间：2020-12-17 浏览量：28
              </div>

              <div>标签：无</div>
            </div>
          </div>
          <div class="detail-content">
            <p>
              复工在即，深圳市企业融资快线2月9日火速开通！深圳市金融工作局联手市政数局开通融资快线小程序在深圳通疫情防控平台上线。深圳企业利用这一款小程序，只须线上提交融资申请即可，线下金融机构会第一时间主动对接，为复工企业带来资金“及时雨”。
            </p>
            <p>
              收到企业填报的融资需求后，市金融工作局将结合即将出台的《关于积极应对新型冠状病毒肺炎疫情促进金融支持企业共渡难关的实施细则》，根据申请企业具体情况，进行分区、分类，组织各区金融工作局（办）和市各金融机构，主动做好对接，充分运用各类政策性金融工具，提供针对性融资服务，将惠企融资各项“干货”政策落到实处。
            </p>
            <p>
              收到企业填报的融资需求后，市金融工作局将结合即将出台的《关于积极应对新型冠状病毒肺炎疫情促进金融支持企业共渡难关的实施细则》，根据申请企业具体情况，进行分区、分类，组织各区金融工作局（办）和市各金融机构，主动做好对接，充分运用各类政策性金融工具，提供针对性融资服务，将惠企融资各项“干货”政策落到实处。
            </p>
            <p>
              收到企业填报的融资需求后，市金融工作局将结合即将出台的《关于积极应对新型冠状病毒肺炎疫情促进金融支持企业共渡难关的实施细则》，根据申请企业具体情况，进行分区、分类，组织各区金融工作局（办）和市各金融机构，主动做好对接，充分运用各类政策性金融工具，提供针对性融资服务，将惠企融资各项“干货”政策落到实处。
            </p>
          </div>
          <!-- <div class="attachment-container">
                        <p>相关附件</p>
                    </div> -->
          <img
            class="bottom-img"
            src="@/assets/images/detail_bottom_icon.png"
          />
        </div>
        <!-- right -->
        <div class="detail-right">
          <CardRight @gotoDeclare="gotoDeclare"></CardRight>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardRight from "@/components/cardRight";

export default {
  components: {
    CardRight,
  },
  data() {
    return {
      id: "",
    };
  },
  created() {
    const that = this;
    const id = that.$route.params.id; // 接收的参数ID
    that.id = id;
  },
  methods: {
    gotoDeclare() {
      if (this.$store.getters.getUserInfo) {
        let childItem = this.$refs.guideCenter.childItem;
        this.$store.commit("updateCurrentDeclareItem", childItem);
        this.$router.push({
          name: "declare",
        });
      } else {
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-detail-content {
  // align-items: center;
  margin-top: 20px;
}

.detail-wrap {
  width: 100%;
  // background-color: red;
  /* 左右 */
  .detail-left {
    width: 800px;
    align-self: flex-start;
  }
  .detail-right {
    width: 300px;
  }
}
.detail-title {
  color: #333;
  width: 100%;
  // background-color: red;
  border-bottom: 1px solid rgba(49, 47, 47, 0.1);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  h1 {
    width: 100%;
    align-self: flex-start;
    padding-bottom: 10px;
    border-bottom: 0.1875rem solid #0d1c28;
    margin-bottom: 20px;
  }
  .share-wrap {
    // width: 100%;
    height: 36px;
    align-self: flex-end;
    // background-color: red;
  }
  p {
    padding: 10px 0;
  }
  .title-wrap {
    margin-top: 10px;
    width: 100%;
    height: 36px;
    line-height: 36px;
    color: #c5c8ca;
  }
}
.detail-content {
  line-height: 28px;
  font-size: 21px;
  margin: 20px 0;
  p {
    text-indent: 2rem;
  }
}
/* 附件 */
.attachment-container {
  width: 100%;
  p {
    padding-bottom: 0.3125rem;
    font-size: 1.25rem;
    border-bottom: 0.1875rem solid #0d1c28;
    color: #1d1e1f;
  }
  a {
    display: block;
    height: 40px;
    padding: 10px 0;
  }
}
/* 底部图片 */
.bottom-img {
  margin-top: 20px;
  width: 100%;
}
</style>